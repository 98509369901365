.approvedCount {
  margin-left: 1.5rem;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-size: 1rem;
}

.btn {
  min-width: 90px;
}
